import React from 'react';
import Bottom from "./parts/Bottom";
import Top from "./parts/Top";
import Button from "./parts/Button";
import {useNavigate} from "react-router-dom";
import mixpanel from 'mixpanel-browser';
import {dataFetch, getItem, getResults, getSurveyData, setSession, setStep} from "./utils/lib";

import Logo from "./parts/Logo";

import {useTranslation, Trans} from "react-i18next";
import RiveLoad from "./parts/RiveLoad";
import Cookies from "js-cookie";
import {trackSignup} from "./utils/tracking";


export default function SignIn ({step, toAdd, nextUrl, preload}) {
  const {t, i18n} = useTranslation();
  // const surveyList = getSurveyData(t('data', {ns: 'survey', returnObjects: true, lng: 'en'}), i18n.resolvedLanguage);
  const surveyList = getSurveyData(t('data', {ns: 'survey', returnObjects: true}), i18n.resolvedLanguage);
  const resultList = getItem('survey_results');

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);
  const [validated, setValidated] = React.useState(false);

  const inputRef = React.useRef();
  const buttonRef = React.useRef();

  // const A5 = getExperiment('A5');
  // const A8 = window.landing === 'masculinity' ? {value: 'A'} : getExperiment('A8');

  React.useEffect(() => {
    if (resultList.length < surveyList.length) {
      navigate('/survey', {replace: true});
      return;
    }

    if (!window.localStorage.getItem('program_results')) {
      navigate('/loading', {replace: true});
      return;
    }

    setStep(step, toAdd);
  }, []);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  function handleSubmit(e) {
    e.preventDefault();

    const email = document.getElementById('email').value;

    if (!validateEmail(email)) {
      alert(t('signIn.checkEmail'));
      return;
    }

    if (
      email.includes('.con') &&
      !window.confirm(t('signIn.checkEmail2'))
    )
      return;

    const getFbp = () => {
        let result = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
        if (!(result && result[1])) {
          return null;
        }
        return result[1];
    };

    const getFpc = () => {
        let result = /_fbc=(fb\.1\.\d+\.\S+)/.exec(window.document.cookie);
        if (!(result && result[1])) {
          return null;
        }
        return result[1].replace(';', '');
    };

    const fbp = getFbp();
    const fbc = getFpc();

    setIsLoading(true);

    const data = {
      lang: i18n.resolvedLanguage,
      age: window.localStorage.getItem('age'),
      email,
      fbp,
      fbc,
      positionList: resultList,
      resultList: getResults(surveyList, resultList),
      positionProgramList: getItem('program_results'),
      session_uid: (window.session_uid || window.sessionStorage.getItem('session_uid')),
      ttclid: Cookies.get('ttclid'),
      ttp: Cookies.get('_ttp'),
    };

    if (!data.session_uid) {
      data.session_uid = 'b-' + (new Date()).getTime() + Math.floor(Math.random() * 1000);
      window.session_uid = data.session_uid;
      setSession({lang: i18n.resolvedLanguage});
    }

    dataFetch(
      process.env.REACT_APP_API_URI + 'web-members',
      data,
      (response) => {
        // const response = {"uid":"02d6b0ff-6dae-44dc-9400-68673ef7dab8"};

        mixpanel.identify(response.uid);
        mixpanel.people.set({email: data.email});

        window.localStorage.setItem('uid', response.uid);
        window.localStorage.setItem('email', data.email);

        trackSignup(data.email)

        navigate(nextUrl);
      }
    );
  }

  return (
    <div className={'wrap fixed'}>
      <Top>
        <div className={'row'} style={{height: '40px', justifyContent: 'center'}}>
          <Logo/>
        </div>

        <h1>{t('signIn.h1')}</h1>

        <p className={'notice'} dangerouslySetInnerHTML={{__html: t('signIn.notice')}}/>

        <form onSubmit={handleSubmit} className={'w-100'} style={{position: 'relative'}}>
          <input type={'submit'} className={'d-none'}/>

          {
            // A7.value === 'A' &&
            // A5.value === 'A' &&
            <div style={{position: 'absolute', right: -25, top: -42, width: 138, height: 93, pointerEvents: 'none'}}>
              <RiveLoad width={138} height={93} src={'email_cat.riv'} stateMachines="email_cat" image={require(`./assets/img/cat.svg`).default}/>
            </div>
          }
          <input ref={inputRef} id={'email'} type={'email'} placeholder={t('signIn.email')}
                 className={'form-control mb-4'} required
                 pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                 onInput={(e) => setValidated(validateEmail(e.target.value))}/>

          <Button doRef={buttonRef} type={'submit'} className={!validated ? 'disabled' : ''} onClick={() => {
            if (!validated) inputRef.current.focus()
          }}>
          {t('signIn.btn')}
            {isLoading && <span className="spinner ml-2"></span>}
          </Button>
        </form>

        <p className={'note md'} style={{fontWeight: 400}}>{t('signIn.info1')}</p>
        <p className={'note md'} style={{fontWeight: 400}}>{t('signIn.info2')}</p>

        <p className={'note md'} style={{fontWeight: 400}}>
          <Trans i18nKey={'signIn.info3'} shouldUnescape={true}>
            Please be aware that your use of Vocal Image is subject to our
            <a rel="noreferrer" href={process.env.REACT_APP_WEB_LINK + 'terms'} target={'_blank'}
               className={'link nowrap'}>Terms &amp; Conditions</a>,
            <a rel="noreferrer" href={process.env.REACT_APP_WEB_LINK + 'privacy'} target={'_blank'}
               className={'link nowrap'}>Privacy Policy</a>, and
            <a rel="noreferrer" href={process.env.REACT_APP_WEB_LINK + 'subscription'} target={'_blank'}
               className={'link nowrap'}>Subscription Policy</a>.
            These documents collectively outline the guidelines and policies governing your interaction with our
            platform.
          </Trans>
        </p>
      </Top>

      <Bottom preload={preload}/>
    </div>
  )
}
