import React from 'react';
import Top from "./parts/Top";
import Plan from "./parts/Plan";
import mixpanel from 'mixpanel-browser';
import {useNavigate} from "react-router-dom";
import InfoPaywall from "./parts/InfoPaywall";
import Payment from "./parts/Payment";
import {getItem, getSurveyData, setStep} from "./utils/lib";
import RiveLoad from "./parts/RiveLoad";
import {Trans, useTranslation} from "react-i18next";
import {LanguageEnum} from "./_business/LanguageEnum";
import {trackPaywall} from "./utils/tracking";


function Timer({id, time, className, minutes, seconds}) {
  const
    min = (Math.floor(time / 60) < 10 ? '0' : '') + Math.floor(time / 60),
    sec = (time % 60 < 10 ? '0' : '') + (time % 60)

  if (minutes)
    return <b className={className} id={id}>{min}</b>
  else if (seconds)
    return <b className={className} id={id}>{sec}</b>

  return (
    <strong className={className} id={id}>{min}:{sec}</strong>
  );
}


function TimerPanel({count, onTime}) {
  const {t} = useTranslation('common', {keyPrefix: 'paywall'});

  let timer;
  const [time, setTime] = React.useState(count);

  React.useEffect(() => {
    timer = setInterval(() => {
      setTime(time => {
        onTime && onTime(time - 1);

        if (time <= 0) {
          mixpanel.track('time is out');
          // onClose && onClose();

          document.querySelectorAll('.reserved').forEach((item) => {
            item.style.display = 'none';
          });
          document.querySelector('.top').removeAttribute('style');

          clearInterval(timer);
        }

        return time - 1
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  React.useEffect(() => {
    if (document.getElementById('time')) {
      document.querySelectorAll('.timing').forEach(function (el) {
        el.innerHTML = document.getElementById('time').innerHTML;
      });
      document.querySelectorAll('.timing-min').forEach(function (el) {
        el.innerHTML = document.getElementById('time').innerHTML.split(':')[0];
      });
      document.querySelectorAll('.timing-sec').forEach(function (el) {
        el.innerHTML = document.getElementById('time').innerHTML.split(':')[1];
      });
    }
  }, [time]);

  if (time <= 0)
    return true;

  return (
    <div className={'panel-timer'}>
      <div className={'wrap px-2 text-center'}>
        <p>
          <Trans i18nKey="expires" t={t}>
            <em>Intro offer expires in:</em> <Timer id={'time'} time={time}/>
          </Trans>
        </p>
      </div>
    </div>
  );
}


export default function Paywall({step, toAdd, nextUrl}) {
  const {t, i18n} = useTranslation();
  const surveyList = getSurveyData(t('data', {ns: 'survey', returnObjects: true}), i18n.resolvedLanguage);

  const [active, setActive] = React.useState();
  const navigate = useNavigate();
  const [timing] = React.useState(15 * 60);

  const resultList = getItem('survey_results');
  const sex =
    +resultList?.[
      surveyList.findIndex(({id}) => id === 'gender')
    ]?.[0] === 0 ? 'boy' : 'girl';

  const goalList = t('paywall.goals', {returnObjects: true});

  // const A5 = getExperiment('A5');
  const A5 = {value: 'A'};

  // const experiment = getExperiment('A1');
  //
  // if (['C', 'D'].includes(experiment.value)) {
  //   goalList.splice(-1);
  //   goalList.splice(5, 1);
  // }

  // const A23 = i18n.resolvedLanguage === 'en' ? getExperiment('A23') : {value: 'A'};
  // const A27 = i18n.resolvedLanguage === 'en' ? getExperiment('A27') : {value: 'A'};
  // const A30 = getExperiment('A30');

  const
    s1 = surveyList.findIndex(({id}) => id === 'target1'),
    g1 =
      window.landing.includes('masculinity')
        ? surveyList[s1].list.findIndex(({id, title}) => id === 'Masculinity' || title === 'Masculinity')
        : +resultList?.[s1]?.[0] || 0;

  const goal = goalList?.[g1] || goalList[0];

  if (!goal.title) {
    try {
      // const
      //   s2 = surveyList.findIndex(({id}) => id === 'target2'),
      //   g2 = +resultList?.[s2]?.[0];

      goal.title = surveyList[s1].list[g1]?.title || surveyList[s1].list[0]?.title; // + ' and ' + surveyList[s2].switch[g1].list[g2].title.toLowerCase();
    } catch(e) {
      console.error(e);
    }
  }

  const goalBoy = goal['boy'];
  const goalSex = goal[sex] || goalBoy;

  const advantagesList =
    i18n.exists(`paywall.info.advantages.list-${g1}`)
      ? t(`paywall.info.advantages.list-${g1}`, {returnObjects: true})
      : null;

  React.useEffect(() => {
    if (!window.localStorage.getItem('uid')) {
      navigate('/');
      return;
    }

    // window.backTimer = setInterval(() => {
      if (window.sessionStorage.getItem('success')) {
        window.sessionStorage.removeItem('success');
        navigate('/', {replace: true});
        return;
      }
    // }, 200);

    setStep(step, toAdd);
    // setExperiment(A4);

    trackPaywall();

    // return () => {
    //   clearInterval(window.backTimer);
    // };
  }, []);

  React.useEffect(() => {
    if (active) {
      window.sessionStorage.setItem(
        'price',
        JSON.stringify({
          id: active.id,
          // book: {
            currency: active.currency,
            currencySymbol: active.currencySymbol,
            full_price: active.book.full_price,
            name: active.book.name,
            price: active.book.price,
            promo: active.book.promo,
            // ...active.book,
          // }
        })
      );
    }
  }, [active]);

  //onTime={(time) => setTiming(time)}
  return (
    <>
      <TimerPanel count={timing}/>

      <div className={'wrap'}>
        <Top className={'gap-3'} style={timing ? {marginTop: '48px'} : {}}>
          <div className={'block items'}>
            <h1 className={'h2 text-center'} dangerouslySetInnerHTML={{__html: t('paywall.h1')}}/>

            <div className={'goal'}>
              <div className={'subtitle' + (goal.title.includes('<br>') ? ' compact' : '')} dangerouslySetInnerHTML={{__html: goal.title}}/>
              <div className={'row gap-0 stretch'}>
                <div className={'sad'}>
                  <b className={'tag-note notice xs mt-1'}>{t('paywall.now')}</b>
                  <img src={require(`./assets/img/paywall/goal/${A5.value === 'B' ? `${goal.id}/sad-${sex}.png` : `sad-${sex}.svg`}`)} width={139} height={120} alt={''} className={'rounded-2 w-100 aspect-2'}/>
                  <strong className={'small'} dangerouslySetInnerHTML={{__html: goalSex.sad || goalBoy.sad}}/>
                </div>
                <div className={'happy'}>
                  <b className={'tag-note notice xs mt-1'} style={{color: 'var(--bg-100)', backgroundColor: 'var(--white-100)'}}>{t('paywall.in3Months')}</b>
                  <img src={require(`./assets/img/paywall/goal/${A5.value === 'B' ? `${goal.id}/happy-${sex}.png` : `happy-${sex}.svg`}`)} width={139} height={120} alt={''} className={'rounded-2 w-100 aspect-2'}/>
                  <strong className={'small'} dangerouslySetInnerHTML={{__html: goalSex.happy || goalBoy.happy}}/>
                </div>
              </div>
              <div className={'info'}>
                <p className={'notice xs'}>{t('paywall.note')}</p>
                <div className={'row'}>
                  <p className={'notice xs'} dangerouslySetInnerHTML={{__html: t('paywall.joined1')}}/>
                  <p className={'notice xs'} dangerouslySetInnerHTML={{__html: t('paywall.joined2')}}/>
                </div>

                {
                  timing > 0 &&
                  <>
                    <strong className={'reserved'}>{t('paywall.reserved.title')}</strong>
                    <p className={'reserved small-sm'} dangerouslySetInnerHTML={{__html: t('paywall.reserved.time')}}/>
                    <p className={'reserved note'} style={{color: '#fff'}}>
                      <Trans i18nKey="paywall.reserved.remaining" t={t}>
                        TIME REMAINING: <Timer time={timing} className={'timing gold'}/>
                      </Trans>
                    </p>
                  </>
                }
              </div>
            </div>

            <RiveLoad width={350} height={238} locale={['de'].includes(i18n.resolvedLanguage)} src={`graphs.riv`} stateMachines="graph" inputName={'localisation_id'} value={LanguageEnum[i18n.resolvedLanguage]}/>

            <div className={'block items'}>
              <strong dangerouslySetInnerHTML={{__html: t('paywall.plan.title')}}/>
              <div className={'paragraph'}>
                {
                  t('paywall.plan.list', {returnObjects: true}).map((item, i) =>
                    <p key={i} className={'notice small row'} style={{gap: '0.75rem'}}>
                      <img src={require('./assets/img/icon/star2.svg').default} alt={'★'}/>
                      {item}
                    </p>
                  )
                }
              </div>
            </div>

            <h3 className={'text-center'} dangerouslySetInnerHTML={{__html: t('paywall.plan.header')}}/>
            <TimerNew g1={g1} timing={timing}/>
            <Plan onChange={item => setActive(item)}/>
          </div>

          <InfoPaywall advantagesList={advantagesList}/>
          <Payment data={active} nextUrl={nextUrl}/>
        </Top>
      </div>
    </>
  )
}


function TimerNew({g1, timing}) {
  const {t} = useTranslation('common', {keyPrefix: 'paywall.plan.offerEndsNew'})

  const
    date = new Date(),
    dateCode =
      date.toLocaleDateString('en', {month: "short", year: "numeric"})
        .replace(' 20', '').toLowerCase()

  const codeList = [
    "commskills",
    "relationship",
    "career",
    "singing",
    "masculine",
    "confidence",
    "public",
    "sexyvoice",
    "leadership"
  ]

  const holidayList = [
    // {
    //   start: '2024-11-29',    // >= yyyy-mm-dd
    //   end: '2024-12-02',      // < yyyy-mm-dd
    //   code: 'blackfriday',    // or list by goal
    // },
    // {
    //   start: '2024-12-02',    // 1 day
    //   // end: '2024-12-03',
    //   // days: 1,
    //   code: 'cybermonday',
    // },
    // {
    //   start: '01-01',         // >= mm-dd every year
    //   // end: '01-08',        // < mm-dd every year
    //   days: 7,                // default 1 day
    //   code: 'newyear',
    // }
  ]

  let code = (codeList[g1] || codeList[0]) + '_' + dateCode

  for (const holiday of holidayList) {
    const
      startDate = new Date(Date.parse((holiday.start.split('-').length < 3 ? date.getFullYear() + '-' : '') + holiday.start)),
      endDate = new Date(
        holiday.end
          ? Date.parse((holiday.end.split('-').length < 3 ? date.getFullYear() + '-' : '') + holiday.end)
          : startDate.getTime() + (holiday.days || 1) * 24 * 60 * 60 * 1000
      )

    if (
      date.getTime() >= startDate.getTime() &&
      date.getTime() < endDate.getTime()
    ) {
      code = holiday.code
      break
    }
  }

  return (
    <div className={'block-plan new-timer'}>
      <div className={'row md wide'}>
        <div className={'items'}>
          <strong>{t('title1')}</strong>
          <div className={'items facing gap-0'} style={{padding: '20px 12px 12px 12px', position: 'relative'}}>
            <div className="promo small"><em><small>33%</small> <strong>{t('discount')}</strong></em></div>
            <s className={'note md'} style={{fontWeight: 'normal'}}>newcomer_oct24</s>
            <b>{code}</b>
          </div>
        </div>
        <div className={'items'}>
          <strong className={'pre-line'}>{t('title2')}</strong>
          <div className={'row xs clock'}>
            <div className={'items facing gap-2'}>
              <Timer minutes={true} time={timing} className={'timing-min'}/>
              <small className={'note'}>{t('minutes')}</small>
            </div>
            <div className={'items facing gap-2'}>
              <Timer seconds={true} time={timing} className={'timing-sec'}/>
              <small className={'note'}>{t('seconds')}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
