import React from 'react';
import Checkbox from "./Checkbox";
import {dataFetch, scrollIntoViewWithOffset} from "../utils/lib";
import Paysafe from "./Paysafe";
import {Trans, useTranslation} from "react-i18next";
import Button from "./Button";

export default function Plan({index, discount, onChange, strict, listInput = [], path = 'prices', data, method = 'GET'}) {
  const {t, i18n} = useTranslation('common', {keyPrefix: 'paywall.plan'})

  const [list, setList] = React.useState(listInput)
  const [active, setActive] = React.useState(listInput[0])

  // const A4 = getExperiment('A4');

  const isForce = window.sessionStorage.getItem('force')

  React.useEffect(() => {
    if (listInput.length)
      return;

    dataFetch(
      `${process.env.REACT_APP_API_URI}web-payments/${path}?lang=${i18n.resolvedLanguage}${isForce ? '&force=1' : ''}`,
      data,
      (response) => {
        if (!Array.isArray(response))
          response = [response];

        setList(response);

        let isActive = false;

        for (const item of response) {
          if (item.selected) {
            isActive = true;
            setActive(item);

            if (onChange)
              onChange(item);

            break;
          }
        }

        if (!isActive) {
          setActive(response[0]);

          if (onChange)
            onChange(response[0]);
        }
      },
      method
    );
  }, [i18n.resolvedLanguage]);

  function handleChange(item) {
    setActive(item);

    if (onChange)
      onChange(item);
  }

  return (
    <>
      <div className={'items w-100'} style={list?.[0]?.promo ? {marginTop: 10} : {}}>
        {
          !active &&
          <iframe title={'Loading...'} src={process.env.PUBLIC_URL + '/static/loader.html'} width="100%" height={74} frameBorder="0" style={discount ? {} : {margin: '94px 0'}}/>
        }
        {
          list.map((item) => {
            const checked = active?.id === item.id;
            // item.standardPrice = 19.9;

            return (
              <div key={item.id}
                   className={'block-plan' + (item.popular ? ' popular' : '') + (checked ? ' active' : '')}
                   style={{position: 'relative'}}
                   onClick={() => handleChange(item)}>
                {
                  item.promo &&
                  <div className={'promo small'}><em>{item.promo}</em></div>
                }
                <div className={'item'}>
                  {
                    list.length > 1 &&
                    <Checkbox type={'radio'} name={'plan' + (index ? '-' + index : '')} checked={checked} readOnly={true}/>
                  }
                  <div className={'w-100 text-start'}>
                    <strong>{item.name}</strong>
                    <p>
                      <em className={'small'}>{item.currency} {item.fullPrice}</em>&nbsp;
                      {
                        (item.standardPrice || item.price) &&
                        <s className={'note lg'}>{item.currency} {item.standardPrice || item.price}</s>
                      }
                    </p>
                  </div>
                  <div className={'nowrap'}>
                    <sup>{item.currencySymbol}</sup>
                    <big>{Math.trunc(item.dayPrice)}</big>
                    <div>
                      <Trans i18nKey="perDay" t={t}>
                        <em>{{dayPrice: Math.round(item.dayPrice % 1 * 100)}}</em>
                        <small>PER DAY</small>
                      </Trans>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>

      {
        !strict &&
        <>
          {
            !discount && // A4.value === 'A' &&
            <>
              <div className={'row sm'}>
                <em style={{marginBottom: '22px'}}>👍</em>
                <div>
                  <p className={'small mb-1'}>{t('getMyPlan.desc')}</p>
                  <p className={'note'} style={{fontWeight: 400}}>{t('getMyPlan.note')}</p>
                </div>
              </div>
              <Button onClick={() => scrollIntoViewWithOffset('#payment', 48)}>{t('getMyPlan.btn')}</Button>
            </>
          }

          <Paysafe/>

          <img src={require('../assets/img/paywall/logos.png')} alt="stripe" className={'mw-100'} width={326} height={22} style={{padding: '0 15px'}} />
        </>
      }
    </>
  )
}
