import React from 'react';
import './assets/css/App.css';
import {Routes, Outlet, Route, Link} from "react-router-dom";
import Main from "./Main";
import Survey from "./Survey";
import Paywall from "./Paywall";
import Success from "./Success";
import Account from "./Account";
import Subscription from "./Subscription";
import Discount from "./Discount";
import Review from "./Review";
import SignIn from "./SignIn";
import EmailOffers from "./EmailOffers";

import riveWasmUrl from '@rive-app/canvas/rive.wasm';
import { RuntimeLoader } from 'rive-react';
import mixpanel from 'mixpanel-browser';

import {useTranslation} from "react-i18next";
import Button from "./parts/Button";
import Listen from "./Listen";
import Summarize from "./Summarize";
import Book from "./Book";


if (process.env.REACT_APP_VERSION !== window.localStorage.getItem('version')) {
  window.localStorage.setItem('version', process.env.REACT_APP_VERSION);

  if (window.localStorage.getItem('survey_results')) {
    console.warn('clear');
    window.localStorage.removeItem('survey_results');
  }
}

RuntimeLoader.setWasmUrl(riveWasmUrl);


export default function App() {
  let i = -1;

  const {i18n} = useTranslation();
  // const A17 = getExperiment('A17');
  // const A17 = {value: 'B'};

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
{/*        <Route index element={  preload="landing.riv"
          <Intro step={{step: ++i, page: 'intro'}} toAdd={false} nextUrl={'/age'} />
        } />*/}

        <Route index element={
          <Main step={{step: ++i, page: 'age'}} toAdd={false} nextUrl={'/survey'} />
        } />
        <Route path="quiz" element={
          <Main step={{step: i, page: 'quiz'}} toAdd={false} nextUrl={'/survey'} />
        } />
        <Route path="masculinity" element={
            <Main step={{step: i, page: 'masculinity'}} toAdd={false} nextUrl={'/survey'} />
        } />
        <Route path="quiz-masculinity" element={
          <Main step={{step: i, page: 'quiz-masculinity'}} toAdd={false} nextUrl={'/survey'} />
        } />
        <Route path="web" element={
          <Main step={{step: i, page: 'web'}} toAdd={false} nextUrl={'/survey'} />
        } />
        <Route path="dash" element={
          <Main step={{step: i, page: 'dash'}} toAdd={false} nextUrl={'/survey'} />
        } />

{/*        <Route path="join" element={
          <Join step={{step: ++i, page: 'join'}} toAdd={false} nextUrl={'/survey'} />
        } />*/}

        <Route path="survey" element={
          <Survey step={++i} nextUrl={'/loading'} />
        } />

        <Route path="loading" element={
          <Review step={{step: ++i, page: 'loading'}} nextUrl={i18n.resolvedLanguage === 'en' ? '/listen' : '/email'} />
        } />
        <Route path="summarize" element={
          <Summarize step={{step: i, page: 'summarize'}} nextUrl={i18n.resolvedLanguage === 'en' ? '/listen' : '/email'} />
        } />
        <Route path="summarize/:uid" element={
          <Summarize step={{step: i, page: 'summarize'}} nextUrl={'/paywall'} />
        } />

        {
          i18n.resolvedLanguage === 'en' &&
          <Route path="listen" element={
            <Listen step={{step: ++i, page: 'listen'}} nextUrl={'/email'} />
          } />
        }

        <Route path="email" element={
          <SignIn step={{step: ++i, page: 'sign-in'}} nextUrl={'/emailoffers'} />
        } />
        <Route path="emailoffers" element={
          <EmailOffers step={{step: ++i, page: 'emailoffers'}} nextUrl={'/paywall'} preload={'graphs.riv'} locale={['de'].includes(i18n.resolvedLanguage)} />
        } />
{/*        <Route path="result" element={ preload={'everybody_notice.riv'}
          <Result step={{step: ++i, page: 'result'}} nextUrl={'/paywall'} />
        } />*/}

        <Route path="paywall" element={
          <Paywall step={{step: ++i, page: 'paywall'}} nextUrl={'/book'} />
        } />
        <Route path="book" element={
          <Book step={{step: ++i, page: 'book'}} nextUrl={'/success'} preload={'success.riv'} />
        } />
        <Route path="success" element={
          <Success step={{step: ++i, page: 'success'}} />
        } />

        <Route path="discount/:uid" element={<Discount />} />

        <Route path="account" element={<Account />} />
        <Route path="subscription" element={<Subscription />} />

        <Route path=".well-known/apple-developer-merchantid-domain-association" element={<Apple />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  const {t} = useTranslation('common', {keyPrefix: 'cookies'});

  const btnStyle = {
    padding: '0.5rem',
    fontSize: '0.75rem',
    lineHeight: '1rem'
  };

  const [cookies, setCookies] = React.useState(
    +window.localStorage.getItem('cookies') > 0
      ? false
      : undefined
  );

  // console.warn('google', JSON.stringify(window.google_tag_data?.ics?.entries.ad_storage))

  React.useEffect(() => {
    window.askConsent = (ask) => {
      // console.log('askConsent', ask)

      if (window.asked || cookies !== undefined)
        return;

      window.asked = true

      clearTimeout(window.consentTimeout)

      if (!ask) {
        // const d = window.dataLayer
        //
        // if (d[0][0] === 'consent') {
          // if (
          //   d[1][0] === 'js' &&
          //   (d[1][1].getTime() + d[0][2].wait_for_update > (new Date()).getTime())
          // ) {
          //   console.log('remove consent')
          //   window.dataLayer.shift() // remove consent
          // } else {
          //   console.warn('ask timeout consent')
            acceptConsent()
          // }
        // }
      }

      // if (cookies === undefined)
        setCookies(ask)

      delete window.askConsent;
    };

    const cookiesStore = +window.localStorage.getItem('cookies')

    if (cookiesStore > 0) {
      if (cookiesStore > 1)
        acceptConsent()

      delete window.askConsent;
    } else {
      const asked = +window.sessionStorage.getItem('ask_consent')

      if (asked > 0) {
        // console.log('from session')
        window.askConsent(asked > 1)
      } else {
        window.consentTimeout = setTimeout(() => {
          console.warn('timeout consent')
          acceptConsent()
        }, 3000)
      }
    }

    return () => {
      delete window.askConsent;
    };
  }, []);

  const acceptConsent = () => {
    if (
      !window.gtag ||
      window.dataLayer[0][0] !== 'consent'
    )
      return;

    // console.log('acceptConsent')
    window.gtag('consent', 'update', {
      'ad_storage': 'granted',
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'analytics_storage': 'granted'
    });
  };

  const handleCookies = (accept) => {
    if (accept)
      acceptConsent();

    window.localStorage.setItem('cookies', accept ? '2' : '1');
    setCookies(false);
  };

  return (
    <>
      <link rel="prefetch" href={riveWasmUrl} as="fetch" crossOrigin="anonymous"/>

      <Outlet />

      {
        cookies &&
        <div style={{position: 'fixed', zIndex: 100, bottom: '0.5rem', left: '50%', transform: 'translate(-50%)', width: '100%', maxWidth: 'var(--max-width)', padding: '0 0.5rem'}}>
          <div className={'panel small-sm'} style={{lineHeight: '1rem', padding: '1rem', borderRadius: '0.5rem'}}>
            <p className={'mb-2'}>{t('text')}</p>
            <div className={'row md'}>
              <Button variant={'secondary'} style={{...btnStyle, color: 'var(--secondary-yellow)'}} onClick={() => handleCookies(false)}>{t('reject')}</Button>
              <Button variant={'gold'} style={btnStyle} onClick={() => handleCookies(true)}>{t('accept')}</Button>
            </div>
          </div>
        </div>
      }
    </>
  );
}

function NotFound() {
  const {t} = useTranslation('common', {keyPrefix: 'notFound'});

  React.useEffect(() => {
    mixpanel.track_pageview({page: '404', url: window.location.href});
  }, []);

  return (
    <div className={'centered'} style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
      <h1>{t('header')}</h1>
      <br />
      <p>
        <Link to="/" className={'marked'}>{t('goHome')}</Link>
      </p>
    </div>
  );
}

function Apple() {
  fetch(require('./assets/apple'), {
    method: 'GET',
    // headers: {
    //   'Content-Type': 'application/pdf',
    // },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `apple-developer-merchantid-domain-association`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
}
