import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";

import {I18nextProvider} from "react-i18next";
import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import mixpanel from 'mixpanel-browser';
import {MixpanelProvider} from 'react-mixpanel';
import {isbot} from "isbot";
import {v4 as uuidv4} from "uuid";
import {setSession} from "./utils/lib";
import {LanguageEnum} from "./_business/LanguageEnum";


mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {track_pageview: true});

const options = {
  // fallbackLng: ["en", "es", "en-US", "dev"],
  fallbackLng: 'en',
  // fallbackLng: {
  //   // 'ru-UA': ['uk'],
  //   'default': ['en']
  // },

  // whitelist: ['en', 'es'],
  // nonExplicitWhitelist: true,
  // load: 'languageOnly',
  // saveMissing: false,

  // ns: ['file1', 'file2'],
  defaultNS: 'common',
  debug: process.env.REACT_APP_DEBUG,

  interpolation: {escapeValue: false},  // React already does escaping
  // lng: 'en',                         // language to use
  resources: {},
};

// const A21 = getExperiment('A21');

for (const lang in LanguageEnum) {
  let lng = lang;

  // if (lang === 'en' && A21.value !== 'A')
  //   lng += '-' + A21.value;

  options.resources[lang] = {
    common: require(`./assets/i18/${lng}/common.json`),
    survey: require(`./assets/i18/${lng}/survey.json`),
  };
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(options, (err, t) => {
    if (err)
      console.error('error while initialising i18next ::: ' + JSON.stringify(err));
  });

document.documentElement.lang = i18n.resolvedLanguage;
i18n.on('languageChanged', (lng) => (document.documentElement.lang = lng))

window.session_uid = window.sessionStorage.getItem('session_uid');
window.landing = window.sessionStorage.getItem('landing') || 'default';

const matched = window.location.href.match(/\/(quiz|masculinity|quiz-masculinity|web|dash)(?:\/|[?#].*|$)/);

if (matched && matched.length > 1)
  window.landing = matched[1];

window.quiz =
  ['quiz', 'quiz-masculinity'].includes(window.landing) ||
  ['quiz', 'quiz-masculinity'].includes(window.sessionStorage.getItem('landing'));

mixpanel.register({
  version: process.env.REACT_APP_VERSION,
  isbot: isbot(navigator.userAgent),
  lang: i18n.resolvedLanguage,
  quiz: window.quiz,
  landing: window.landing,
});

if (!window.session_uid) {
  console.log({page: 'start'});
  mixpanel.track_pageview({page: 'start'});

  window.sessionStorage.setItem('landing', window.landing);

  // console.log({step: -1, page: 'loader'});
  // mixpanel.track_pageview({step: -1, page: 'loader'});
  // // setStep({step: -1, page: 'loader'}, false);
  //
  // window.loaderCallback = () => {
  //   window.loaderDone = true;
  //   document.body.classList.remove('pop');
  //   window.loader.remove();
  // };
  //
  // window.loader = document.createElement('div');
  // window.loader.setAttribute('class', 'spinner-fixed');
  // window.loader.innerHTML =
  //   `<iframe title="Loading..." src="${process.env.PUBLIC_URL}/static/loader-logo.html" width="80" height="80" frameBorder="0"
  //         onload="setTimeout(window.loaderCallback, 3000);" onerror="window.loaderCallback()"/>`;
  //
  // document.body.prepend(window.loader);
  // document.body.classList.add('pop');

  window.session_uid = uuidv4();

  // FIXME: not need?
  if (!window.session_uid)
    window.session_uid = 'a-' + (new Date()).getTime() + Math.floor(Math.random() * 1000);

  setSession({lang: i18n.resolvedLanguage});
}
// else
//   window.loaderDone = true;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <MixpanelProvider mixpanel={mixpanel}>
          <App/>
        </MixpanelProvider>
      </BrowserRouter>
    </I18nextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
